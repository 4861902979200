/* eslint-disable max-len */

const stripeCostumerPortalId =
  process.env.NODE_ENV === 'development'
    ? 'https://billing.stripe.com/p/login/test_00g03Baridv2dSodQQ'
    : 'https://billing.stripe.com/p/login/28ocQ77kqfTV71SbII';

export const CONFIG = {
  stripeCustomerPortalURL: (userEmail: string | null) =>
    `${stripeCostumerPortalId}?prefilled_email=${userEmail}`,

  stripePricingTable: {
    pricingTableId:
      process.env.NODE_ENV === 'development'
        ? 'prctbl_1O9ubNJUtLKfhM5NYzr0Vb24'
        : 'prctbl_1OeMyLJUtLKfhM5NLBVfFChM',

    publishableKey:
      process.env.NODE_ENV === 'development'
        ? 'pk_test_51O9a8oJUtLKfhM5NIZOgeqpP8kU3JbDmTYZncLB3LwFaeXp6BKjM1bihACfo5WswbidKpMD4ZW3t9Dwz6KRAPMrC00RfQVC3nk'
        : 'pk_live_51O9a8oJUtLKfhM5NxFmYyZcfBUu7QOpxEsL3EI6Ovois6kPjakTeak1hIuaPku9kobmO8WP1sJRvk1He3NfsNyEZ00LmaXOEjn',
  },

  firebaseFunctions: {
    baseUrl:
      process.env.NODE_ENV === 'development'
        ? 'http://127.0.0.1:5001/genialma-app/us-central1/widgets'
        : 'https://widgets-nsomw4bg5a-uc.a.run.app',
  },
};
