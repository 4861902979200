/* eslint-disable max-len */
interface GTextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {}

export default function GTextarea(props: GTextareaProps) {
  const { className } = props;
  return (
    <textarea
      {...props}
      className={`p-4 w-full rounded-[13px] h-[200px] border border-[#b8b8b8] ${
        className || ''
      }`}
    />
  );
}
