/* eslint-disable max-len */
import { Suspense, lazy, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";
import { GenialmaFirebase } from './firebaseController';
import { getDatabase, onDisconnect, ref } from 'firebase/database';
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
// import Chat from './screens/Chat/Chat';
// import Home from './screens/Home/Home';
import {
  HiHome,
  HiCog6Tooth,
  HiChatBubbleBottomCenterText,
} from 'react-icons/hi2';
// import Login from './screens/Login/Login';
// import Pricing from './screens/Pricing/Pricing';
import { Subscription } from './databaseSchema';
import styled from 'styled-components';
import { GenialmaFeatures } from './screens/types';
import { firebaseConfig } from './firebaseConfig';
// import Backoffice from './screens/Backoffice/Backoffice';
// import Welcome from './screens/Welcome';
import Success from './screens/Success/Success';
import HelpButton from './components/HelpButton/HelpButton';
import Help from './screens/Help/Help';
import GLoading from './components/GLoading/GLoading';

const LazyChat = lazy(() => import('./screens/Chat/Chat'));
const LazyHome = lazy(() => import('./screens/Home/Home'));
const LazyLogin = lazy(() => import('./screens/Login/Login'));
const LazyPricing = lazy(() => import('./screens/Pricing/Pricing'));
const LazyWelcome = lazy(() => import('./screens/Welcome'));
const LazyBackoffice = lazy(() => import('./screens//Backoffice/Backoffice'));
const LazySettings = lazy(() => import('./screens/Settings/Settings'));
const LazyDesabafar = lazy(() => import('./screens/Desabafar/Desabafar'));

firebase.initializeApp(firebaseConfig);
const db = getDatabase();
const genialmaFirebase = new GenialmaFirebase(db);

export default function App() {
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [stripeCustomerId, setStripeCustomerId] = useState<string | null>(null);
  const [enabledFeatures, setEnabledFeatures] = useState<GenialmaFeatures>({
    textMessage: false,
    audioMessage: false,
    messagesLimitPerMonth: 1,
    hasPriority: false,
    canEditMessages: false,
  });
  const [loading, setLoading] = useState(false);

  // Listens for customer subscription changes
  useEffect(() => {
    if (stripeCustomerId) {
      genialmaFirebase.listenToSubscriptionChange(setSubscription, {
        stripeCustomerId,
      });
    }
  }, [stripeCustomerId]);

  // Listens for customer ID subscription changes
  useEffect(() => {
    if (loggedInUser) {
      genialmaFirebase.listenToStripeCustomerIdChange(setStripeCustomerId, {
        userId: loggedInUser.uid,
      });
    }
  }, [loggedInUser]);

  // Enables or disables features based on the user's current subscription status.
  useEffect(() => {
    // If admin, enable all features.
    if (isAdmin(loggedInUser?.uid)) {
      setEnabledFeatures({
        audioMessage: true,
        messagesLimitPerMonth: 'unlimited',
        textMessage: true,
        hasPriority: true,
        canEditMessages: true,
      });
      return;
    }

    if (subscription?.status !== 'active') {
      setEnabledFeatures({
        textMessage: true,
        audioMessage: false,
        messagesLimitPerMonth: 1,
        hasPriority: false,
        canEditMessages: false,
      });
      return;
    }

    switch (subscription?.productName) {
      case '☀️ Plano Sol':
        setEnabledFeatures({
          textMessage: true,
          audioMessage: true,
          messagesLimitPerMonth: 'unlimited',
          hasPriority: true,
          canEditMessages: true,
        });
        break;
      case '🌍 Plano Terra':
        setEnabledFeatures({
          textMessage: true,
          audioMessage: false,
          messagesLimitPerMonth: 'unlimited',
          hasPriority: false,
          canEditMessages: false,
        });
        break;

      default:
        break;
    }
  }, [subscription]);

  // Listens to authentication changes
  useEffect(() => {
    const auth = getAuth();
    setLoading(true);

    onAuthStateChanged(auth, (user) => {
      // Disconnect the user from the database when they leave the app
      const presenceRef = ref(db, `users_online/${user?.uid}`);
      onDisconnect(presenceRef).set(null);

      if (user) {
        setLoading(false);
        // setEnableAllFeaturesIfAdmin(user?.uid);
        // Send verification email
        // if (!user.emailVerified) {
        //   sendEmailVerification(user);
        // }

        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        toast.success('Sessão iniciada.');
        setLoggedInUser(user);
        genialmaFirebase.writeUsersOnline('add', user?.uid);
      } else {
        setLoading(false);
        setLoggedInUser((prevState) => {
          return null;
        });
      }
    });
  }, []);

  function isAdmin(uid?: string) {
    if (
      uid === 'M4PO56IyVqbwkSNNFDB8Dr08n1R2' ||
      uid === 'Knqn3X8daoWtF18kprkdGqxDRwB3' ||
      uid === 'ECP7E0jAQxMdZTv1zQRK0ndzuHg2'
    ) {
      return true;
    } else {
      return false;
    }
  }

  if (loading) {
    return <GLoading />;
  }

  return (
    <AppContainer id="finess">
      <Suspense fallback={<GLoading />}>
        <BrowserRouter>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Flip}
            theme="colored"
          />

          {/* <HelpButton /> */}

          <Routes>
            <Route
              path="/"
              element={
                loggedInUser ? (
                  <LazyHome
                    genialmaFirebase={genialmaFirebase}
                    user={loggedInUser}
                  />
                ) : (
                  <LazyWelcome genialmaFirebase={genialmaFirebase} />
                )
              }
            />

            <Route
              path="desabafar"
              element={<LazyDesabafar genialmaFirebase={genialmaFirebase} />}
            />

            <Route path="/login" element={<LazyLogin />} />

            <Route
              path="/chat"
              element={
                loggedInUser ? (
                  <LazyChat
                    enabledFeatures={enabledFeatures}
                    user={loggedInUser}
                    database={db}
                    genialmaFirebase={genialmaFirebase}
                  />
                ) : (
                  <LazyLogin />
                )
              }
            />

            <Route
              path="/help"
              element={<Help genialmaFirebase={genialmaFirebase} />}
            />

            <Route
              path="/settings"
              element={
                loggedInUser ? (
                  <LazySettings
                    genialmaFirebase={genialmaFirebase}
                    user={loggedInUser}
                    subscription={subscription}
                  />
                ) : (
                  <LazyLogin />
                )
              }
            />

            <Route
              path="/pricing"
              element={
                loggedInUser ? (
                  <LazyPricing user={loggedInUser} />
                ) : (
                  <LazyLogin />
                )
              }
            />

            <Route
              path="/welcome"
              element={<LazyWelcome genialmaFirebase={genialmaFirebase} />}
            />

            <Route path="/success" element={<Success />} />

            <Route
              path="/kyky"
              element={
                loggedInUser ? (
                  <LazyBackoffice
                    genialmaFirebase={genialmaFirebase}
                    adminId={loggedInUser?.uid}
                    user={loggedInUser}
                  />
                ) : null
              }
            />
          </Routes>
          {loggedInUser?.uid && <Footer />}
        </BrowserRouter>
      </Suspense>
    </AppContainer>
  );
}

function Footer() {
  const location = useLocation();
  if (location.pathname === '/kyky') return null;

  return (
    <FooterSection>
      <div className="limiter">
        <FooterAnchor href="/">
          <>
            <HiHome />
            <CustomSmall>Principal</CustomSmall>
          </>
        </FooterAnchor>

        <FooterAnchor href="/chat">
          <>
            <HiChatBubbleBottomCenterText />
            <CustomSmall>Conversar</CustomSmall>
          </>
        </FooterAnchor>

        <FooterAnchor href="/settings">
          <>
            <HiCog6Tooth />
            <CustomSmall>Configurar</CustomSmall>
          </>
        </FooterAnchor>
      </div>
    </FooterSection>
  );
}

const FooterSection = styled.footer`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  color: white;
  background: #1a73e8;
  overflow: hidden;

  .limiter {
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin: 0em 1em 0em 1em;
  }
`;

function FooterAnchor({
  href,
  children,
}: {
  href: string;
  children: JSX.Element;
}) {
  const location = useLocation();

  return (
    <CustomLink
      onClick={() => {
        try {
          navigator.vibrate(200);
        } catch {}
      }}
      $selected={href === location.pathname}
      to={href}
    >
      {children}
    </CustomLink>
  );
}

const CustomLink = styled(Link)<{ $selected?: boolean }>`
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.25rem /* 36px */;
  line-height: 2.5rem /* 40px */;
  text-shadow: ${(props) => (props.$selected ? 'unset' : '1px 1px 1px black')};
  color: ${(props) => (props.$selected ? '#154c94' : '#ffffff')};
  animation: ${(props) =>
    props.$selected ? 'upwardMovement 0.275s' : 'unset'};

  @keyframes upwardMovement {
    0% {
      transform: translateY(0px);
      rotate: 355deg;
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0px);
      rotate: 360deg;
    }
  }
  /* &:hover {
    opacity: 1;
    color: white;
  } */
`;

const CustomSmall = styled('small')`
  font-size: 0.3em;
  line-height: 100%;
`;

const AppContainer = styled.div`
  position: relative;
  height: fit-content;
  padding-bottom: 70px;
  width: 100%;

  .limited-width {
    max-width: 800px;
  }

  .finess {
    position: relative;
    height: fit-content;
    padding-bottom: 70px;
  }
`;
