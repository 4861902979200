/* eslint-disable max-len */
import {
  Database,
  child,
  connectDatabaseEmulator,
  endAt,
  endBefore,
  get,
  getDatabase,
  limitToFirst,
  limitToLast,
  onValue,
  orderByChild,
  orderByKey,
  orderByValue,
  push,
  query,
  ref,
  remove,
  set,
  startAt,
  update,
} from 'firebase/database';
import {
  DateISOString,
  Message,
  PendingChat,
  Subscription,
  UserID,
} from './databaseSchema';
import { Dispatch, SetStateAction } from 'react';
import {
  UploadResult,
  connectStorageEmulator,
  getDownloadURL,
  getStorage,
  ref as refStorage,
  uploadBytes,
} from 'firebase/storage';
import { User } from 'firebase/auth';
import { sha256 } from 'js-sha256';
// import lamejs from 'lamejs';
const lamejs = require('lamejs');

export interface WriteMessageSettings {
  userId: string;
  role: 'admin' | 'user';
  sentById: string;
  audioBlob?: Blob;
  message?: string;
  user?: User;
  hasPriority: boolean;
  photoURL: string | null;
}

export class GenialmaFirebase {
  public genialmaDatabase: Database;

  constructor(database: Database) {
    this.genialmaDatabase = database;
    try {
      if (
        window.location.hostname === 'localhost' ||
        window.location.hostname === '192.168.0.170' ||
        window.location.hostname.includes('ngrok')
      ) {
        // Point to the RTDB emulator running on localhost.
        connectDatabaseEmulator(this.genialmaDatabase, 'localhost', 9000);
        // Point to the Storage emulator running on localhost.
        connectStorageEmulator(getStorage(), 'localhost', 9199);
      }
    } catch (e) {
      console.error('Error with genialma controller.');
    }
  }

  async compressedMp3Blob(audioBlob: Blob) {
    var mp3Data = [];
    var mp3encoder = new lamejs.Mp3Encoder(1, 44100, 128); //mono 44.1khz encode to 128kbps
    var samples = new Int16Array(44100); //one second of silence replace that with your own samples
    var mp3Tmp = mp3encoder.encodeBuffer(audioBlob); //encode mp3

    //Push encode buffer to mp3Data variable
    mp3Data.push(mp3Tmp);

    // Get end part of mp3
    mp3Tmp = mp3encoder.flush();

    // Write last data to the output data, too
    // mp3Data contains now the complete mp3Data
    mp3Data.push(mp3Tmp);

    const compressedAudioBlob = new Blob(mp3Data, { type: 'audio/mp3' });

    return compressedAudioBlob;
  }

  async storeRecording(blob: Blob, filename: string): Promise<UploadResult> {
    return new Promise(async (resolve, reject) => {
      const storage = getStorage();
      const storageRef = refStorage(storage, filename);

      if (!blob) return;

      const compressedBlob = await this.compressedMp3Blob(blob);

      uploadBytes(storageRef, blob)
        .then((snapshot) => {
          resolve(snapshot);
        })
        .catch((error) => reject(error));
    });
  }

  public readUsername(userId: string): Promise<null | string> {
    return new Promise((resolve, reject) => {
      const dbQuery = query(
        ref(this.genialmaDatabase, `/users/${userId}/username`)
      );

      get(dbQuery)
        .then((snapshot) => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            resolve(null);
          }
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }

  public getAudioFileUrl(userId: string, messageId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      getDownloadURL(refStorage(storage, `${userId}/${messageId}`))
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'

          // This can be downloaded directly:
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
          };
          xhr.open('GET', url);
          xhr.send();

          resolve(url);
        })
        .catch((error) => {
          reject(error);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
          }
        });
    });
  }

  public async writeMessage(settings: WriteMessageSettings): Promise<void> {
    const {
      role,
      sentById,
      userId,
      audioBlob,
      message,
      user,
      hasPriority,
      photoURL,
    } = settings;
    // Get a key for a new message in the chat.
    const newChatKey = push(child(ref(this.genialmaDatabase), 'chat')).key;

    const payload: Message = {
      sentById,
      timestamp: new Date().toISOString(),
    };

    if (message) {
      payload.message = message;
    }

    if (audioBlob) {
      const storeRecordingResult = await this.storeRecording(
        audioBlob,
        `${userId}/${newChatKey}`
      );
      const fileURL = await this.getAudioFileUrl(userId, newChatKey!);
      payload.audio = fileURL;
      // payload.audio = audio;
    }

    const pendingChatsData: PendingChat = {
      userId,
      lastMessageAt: new Date().toISOString(),
      userEmail: user?.email || 'none',
      hasPriority,
      photoURL,
    };

    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates: Record<string, any> = {};

    if (role === 'admin') {
      updates[`/users/${userId}/chat/${newChatKey}`] = payload;
      // updates[`/pending_chats/${userId}/`] = null;
    }

    if (role === 'user') {
      updates[`/users/${userId}/lastMessageSentByUser`] =
        new Date().toISOString();
      updates[`/users/${userId}/chat/${newChatKey}`] = payload;
      updates[`/pending_chats/${userId}/`] = pendingChatsData;
    }

    return update(ref(this.genialmaDatabase), updates);
  }

  public async editMessage({
    userId,
    messageId,
    newMessage,
  }: {
    userId: string;
    messageId: string;
    newMessage: string;
  }): Promise<void> {
    const updates: Record<string, any> = {};

    updates[`/users/${userId}/chat/${messageId}/edited`] =
      new Date().toISOString();
    updates[`/users/${userId}/chat/${messageId}/message`] = newMessage;

    return update(ref(this.genialmaDatabase), updates);
  }

  public listenToPendingChats(
    callback: Dispatch<SetStateAction<Record<string, PendingChat> | null>>
  ) {
    const pendingChats = ref(this.genialmaDatabase, '/pending_chats/');
    onValue(pendingChats, (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  public readChat(
    callback: Dispatch<SetStateAction<Record<string, Message> | null>>,
    settings: {
      userId: string;
      limitToLast: number;
      endBeforeKey?: string;
    }
  ) {
    let messages = null;

    if (settings?.endBeforeKey && settings?.limitToLast && settings?.userId) {
      messages = query(
        ref(this.genialmaDatabase, `/users/${settings?.userId}/chat/`),
        orderByKey(),
        endBefore(settings.endBeforeKey), // THIS IS WORKING!! USE ITTT!! YEAAH!
        limitToLast(settings.limitToLast)
      );
    } else {
      messages = query(
        ref(this.genialmaDatabase, `/users/${settings?.userId}/chat/`),
        orderByKey(),
        limitToLast(settings?.limitToLast)
      );
    }

    onValue(messages, (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  public listenToStripeCustomerIdChange(
    callback: React.Dispatch<React.SetStateAction<string | null>>,
    settings: { userId: string }
  ) {
    const stripeCustomerId = ref(
      this.genialmaDatabase,
      `/users/${settings?.userId}/stripeCustomerId`
    );

    onValue(stripeCustomerId, (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  public listenToSubscriptionChange(
    callback: React.Dispatch<React.SetStateAction<Subscription | null>>,
    settings: { stripeCustomerId: string }
  ) {
    const subscription = ref(
      this.genialmaDatabase,
      `/subscriptions/${settings?.stripeCustomerId}/`
    );

    onValue(subscription, (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  public readChatQuery(settings: {
    userId: string;
    limitToLast?: number | null;
    endBeforeKey?: string | null;
  }) {
    let chatQuery = null;

    if (settings?.endBeforeKey && settings?.limitToLast && settings?.userId) {
      chatQuery = query(
        ref(this.genialmaDatabase, `/users/${settings?.userId}/chat/`),
        orderByKey(),
        endBefore(settings.endBeforeKey), // THIS IS WORKING!! USE ITTT!! YEAAH!
        limitToLast(settings.limitToLast)
      );
    } else {
      chatQuery = query(
        ref(this.genialmaDatabase, `/users/${settings?.userId}/chat/`),
        orderByKey(),
        limitToLast(settings?.limitToLast || 1)
      );
    }

    return chatQuery;
  }

  public getMessageById(userId: string, messageId: string) {
    const q = query(
      ref(this.genialmaDatabase, `/users/${userId}/chat/${messageId}`)
    );

    return get(q);
  }

  public readUsersOnline(callback: (data: Record<UserID, 1>) => void) {
    const usersOnline = ref(this.genialmaDatabase, '/users_online/');
    onValue(usersOnline, (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  public async readLastMessageSentByUser(
    userId: string,
    callback: (userLastMessageDate: string) => void
  ) {
    const lastMessageSentByUser = ref(
      this.genialmaDatabase,
      `users/${userId}/lastMessageSentByUser`
    );

    onValue(lastMessageSentByUser, (snapshot) => {
      const dataISOString: string = snapshot.val();

      callback(dataISOString);
    });
  }

  public writeUsersOnline(
    action: 'add' | 'remove',
    userId?: string | false | undefined
  ) {
    // Adds the user to the online list.
    if (action === 'add') {
      const updates: Record<string, any> = {};
      updates[`/users_online/${userId}/`] = 1;
      return update(ref(this.genialmaDatabase), updates);
    }

    // Removes the user from the online list.
    if (action === 'remove') {
      remove(ref(this.genialmaDatabase, `/users_online/${userId}/`));
    }
  }

  public async writeUsername(
    userId?: string | false | undefined,
    username?: string
  ) {
    if (!username || !userId) return;

    const updates: Record<string, any> = {};
    updates[`/users/${userId}/username`] = username;

    return update(ref(this.genialmaDatabase), updates);
  }

  public addEmailToEmailList(email: string) {
    if (!email) return;
    const now = new Date().toISOString();
    const hashedEmail = sha256(email);

    const updates: Record<string, { email: string; date: DateISOString }> = {};
    updates[`/emailslist/${hashedEmail}`] = { email, date: now };

    return update(ref(this.genialmaDatabase), updates);
  }
}
