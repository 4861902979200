import styled, { css } from 'styled-components';

interface GButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
  $asText?: boolean;
  $fontSize?: string;
}

export default function GButton(props: GButtonProps) {
  const { children, $asText, $fontSize } = props;
  return (
    // @ts-ignore
    <StyledButton {...props} $asText={$asText} $fontSize={$fontSize}>
      {children}
    </StyledButton>
  );
}

const StyledButton = styled('button')<{
  $asText?: boolean;
  $fontSize?: string;
}>`
  background: ${(props) => (props.$asText ? 'unset' : '#1a73e8')};
  color: ${(props) => (props.$asText ? '#1a73e8' : 'white')};
  border-radius: 2em;
  padding: ${({ $asText }) =>
    $asText ? '0.3em 0.3em 0.3em 0.3em' : '0.5em 1em 0.5em 1em'};
  transition: 0.2s background;
  width: auto;
  /* min-width: 110px; */
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '1.1em')};
  display: flex;
  align-items: center;
  justify-content: center;
  word-spacing: 3px;
  box-shadow: ${({ $asText }) => ($asText ? 'unset' : '#0000003b 0px 1px 0px')};

  &:hover {
    background: #105fc7;

    // Hover styles for $asText button.
    ${(props) =>
      props.$asText &&
      css`
        background: unset;
        text-decoration: underline;
        color: #1457b0;
      `};
  }
  &:disabled,
  &[disabled] {
    background-color: #d0dde9;
    color: #6e7c8a;

    // $asText === true
    ${(props) =>
      props.$asText &&
      css`
        background-color: unset;
        color: grey;
        text-decoration: none;
      `}
  }

  &:active {
    box-shadow: inset 2px 2px 2px 0px #072d5f;
    background: #12488f;

    // $asText === true
    ${(props) =>
      props.$asText &&
      css`
        box-shadow: unset;
        background-color: unset;
        text-decoration: none;
      `}
  }
`;
