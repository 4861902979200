import styled from 'styled-components';

/* eslint-disable max-len */
interface BoxProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: JSX.Element;
  color?: string;
}

export default function Box(props: BoxProps) {
  const { children } = props;
  return <StyledBox {...props}>{children}</StyledBox>;
}

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  margin: 0.5rem;
  border-radius: 0.75rem;
  background: #fbfbfb;
  border: 1px solid white;
  box-shadow: -1px -1px 0px 0px #00000012;
`;
