import styled from 'styled-components';

function Centerizer({ children }: { children: React.ReactNode }) {
  return (
    <StyledCenterizer>
      <div className="limited-width">{children}</div>
    </StyledCenterizer>
  );
}

const StyledCenterizer = styled.section`
  display: flex;
  justify-content: center;

  .limited-width {
    max-width: 800px;
    width: 100%;
  }
`;

export default Centerizer;
