import styled from 'styled-components';
import dotsLoader from '../../assets/dots_loader.svg';
import genialmaLogo from '../../assets/logov1_white.svg';

export default function GLoading() {
  return (
    <SuspenseLoader>
      <>
        <img className="logo" alt="Logo do Genialma" src={genialmaLogo} />
        <br />
        <p>
          {
            <img
              className="logo"
              alt="Logo do Genialma"
              width={'50px'}
              src={dotsLoader}
            />
          }
        </p>
      </>
    </SuspenseLoader>
  );
}

const SuspenseLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #00081ac7;
  color: white;
  position: fixed;
  z-index: 100;
  left: 0;
  animation: fadeIn 1s;

  img {
    max-width: 100px;
    animation: pulseAnimation 1s infinite;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes pulseAnimation {
    0% {
      opacity: 0.3;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.3;
    }
  }
`;
