import GButton from '../GButton/GButton';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IoMdHelpCircle } from 'react-icons/io';

export default function HelpButton() {
  const location = useLocation();
  const navigate = useNavigate();

  // if (
  //   location?.pathname === '/welcome' ||
  //   location?.pathname === '/help' ||
  //   location?.pathname === '/kyky'
  // ) {
  //   return null;
  // }

  return (
    <HelpButtonContainer>
      <GButton $asText onClick={() => navigate('/help')}>
        <IoMdHelpCircle />
        <small>ajuda</small>
      </GButton>
    </HelpButtonContainer>
  );
}

const HelpButtonContainer = styled.div`
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  z-index: 20;
  align-items: center;
  font-size: 1.5em;

  small {
    font-size: 10px;
  }

  button {
    min-width: 1em;
    padding: unset;
    display: flex;
    flex-direction: column;
    color: white;
  }

  @media screen and (max-width: 850px) {
    button {
      color: white;
    }
  }
`;
