import { ReactNode } from 'react';
import styled from 'styled-components';
import {
  LuCheckCircle2,
  LuAlertTriangle,
  LuInfo,
  LuXCircle,
} from 'react-icons/lu';

type GMessageStatus = 'error' | 'warning' | 'info' | 'success';

interface GMessageProps {
  children: ReactNode;
  status: GMessageStatus;
  title?: string;
}

type StyledGMessageType = {
  $status: GMessageStatus;
};

export default function GMessage(props: GMessageProps) {
  const { children, status, title } = props;

  return (
    <StyledGMessage $status={status}>
      <div className="title">
        <TitleByStatus status={status} title={title} />
      </div>
      <div className="content">{children}</div>
    </StyledGMessage>
  );
}

function TitleByStatus(props: { status: GMessageStatus; title?: string }) {
  const { status, title } = props;

  let icon = <LuCheckCircle2 />;
  let text = '';

  switch (status) {
    case 'error':
      icon = <LuXCircle />;
      text = 'Erro';
      break;
    case 'warning':
      icon = <LuAlertTriangle />;
      text = 'Aviso';
      break;
    case 'info':
      icon = <LuInfo />;
      text = 'Informação';
      break;
    case 'success':
      icon = <LuCheckCircle2 />;
      text = 'Sucesso';
      break;

    default:
      break;
  }

  return (
    <div className="flex items-center">
      {icon}&nbsp;&nbsp;<b>{title ? title : text}</b>
    </div>
  );
}

const StyledGMessage = styled('div')<StyledGMessageType>`
  padding: 6px 16px;
  width: 100%;
  border-radius: 4px;

  .title {
    font-size: 1.1em;
    margin-bottom: 0.5em;
  }

  .content {
    margin-left: 1.5em;
  }

  background: ${(props) => {
    let background = 'white';
    switch (props.$status) {
      case 'error':
        background = '#fdeded';
        break;
      case 'warning':
        background = '#fff4e5';
        break;
      case 'info':
        background = '#e5f6fd';
        break;
      case 'success':
        background = '#edf7ed';
        break;

      default:
        break;
    }

    return background;
  }};

  color: ${(props) => {
    let color = 'white';
    switch (props.$status) {
      case 'error':
        color = '#620b00';
        break;
      case 'warning':
        color = '#601d00';
        break;
      case 'info':
        color = '#002657';
        break;
      case 'success':
        color = '#002800';
        break;

      default:
        break;
    }

    return color;
  }};
`;
