/* eslint-disable max-len */
interface GInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

export default function GInput(props: GInputProps) {
  return (
    <input
      className="p-4 w-full rounded-[13px] h-[47px] border border-[#b8b8b8]"
      {...props}
    />
  );
}
