// Your web app's Firebase configuration

import { FirebaseOptions } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyBy6uhWBbh8eLdqnDrriHz0lcc2fWKUjGU",
  authDomain: "genialma-app.firebaseapp.com",
  projectId: "genialma-app",
  storageBucket: "genialma-app.appspot.com",
  messagingSenderId: "1084659479473",
  appId: "1:1084659479473:web:a95f476e539d441a874390",
  // measurementId: "G-FHV5E0T8V0",
  databaseURL:
    "https://genialma-app-default-rtdb.europe-west1.firebasedatabase.app/",
};
