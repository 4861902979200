/* eslint-disable max-len */
import styled from 'styled-components';
import Box from '../../components/Box/Box';
import Centerizer from '../../components/Centerizer/Centerizer';
import GScreenHeader from '../../components/GScreenHeader/GScreenHeader';
import HelpForm from '../../components/HelpForm/HelpForm';
import { Link } from 'react-router-dom';
import { GenialmaFirebase } from '../../firebaseController';
import TopBar from '../../components/TopBar/TopBar';

interface HelpProps {
  genialmaFirebase: GenialmaFirebase;
}

export default function Help(props: HelpProps) {
  const { genialmaFirebase } = props;

  return (
    <Centerizer>
      {/* <Link to="/">
        <GScreenHeader text="Ajuda" />
      </Link> */}
      <TopBar hideHelp hideSignIn />
      <br /><br /><br />

      <CustomBox>
        <HelpForm genialmaFirebase={genialmaFirebase} />
      </CustomBox>
    </Centerizer>
  );
}
const CustomBox = styled(Box)`
  display: flex;
  align-items: center;
`;
