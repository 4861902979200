import { Link } from 'react-router-dom';
import genialmaLogo from '../../assets/logov1_white.svg';
import styled from 'styled-components';
import GButton from '../GButton/GButton';
import HelpButton from '../HelpButton/HelpButton';
interface TopBarProps {
  hideHelp?: boolean;
  hideSignIn?: boolean;
}
export default function TopBar({ hideHelp, hideSignIn }: TopBarProps) {
  return (
    <TopBarContainer>
      <div className="menu">
        <Link to="/welcome">
          <img className="logo" alt="Logo do Genialma" src={genialmaLogo} />
        </Link>

        {!hideHelp && <HelpButton />}

        {!hideSignIn && (
          <GButton>
            <Link to="/login?create=true">Iniciar sessão</Link>
          </GButton>
        )}
      </div>
    </TopBarContainer>
  );
}

const TopBarContainer = styled.div`
  width: 100%;
  background: #121f3ef7;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: #00000070 0px 1px 20px 1px;
  height: 57px;

  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1000px;
    width: 100%;
    padding: 8px;

    img {
      width: 70px;
    }

    a {
      color: white;
    }
  }
`;
