/* eslint-disable max-len */
import { useState } from 'react';
import { CONFIG } from '../../config';
import { getAuth } from 'firebase/auth';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import GButton from '../GButton/GButton';
import GMessage from '../GMessage/GMessage';
import GTextarea from '../GTextarea/GTextarea';
import GInput from '../GInput/GInput';
import { GenialmaFirebase } from '../../firebaseController';

interface HelpFormData {
  email: string | null;
  message: string | null;
}

interface HelpFormProps {
  emailInputPlaceholder?: string;
  messageInputPlaceholder?: string;
  submitButtonText?: string;
  introElement?: JSX.Element;
  onSubmit?: (email: string | null, message: string | null) => void;
  genialmaFirebase: GenialmaFirebase;
  submitButtonFontSize?: string;
}

export default function HelpForm(props: HelpFormProps) {
  const {
    introElement,
    submitButtonText,
    submitButtonFontSize,
    emailInputPlaceholder,
    messageInputPlaceholder,
    // onSubmit,
    genialmaFirebase,
  } = props;
  const auth = getAuth();
  const { currentUser } = auth;

  const [helpFormData, setHelpFormData] = useState<HelpFormData>({
    email: currentUser?.email || null,
    message: null,
  });

  const [messageSent, setMessageSent] = useState(false);

  const [loading, setLoading] = useState(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    // if (onSubmit) {
    //   onSubmit(helpFormData.email, helpFormData.message);
    //   return;
    // }

    if (helpFormData.email) {
      genialmaFirebase.addEmailToEmailList(helpFormData.email);
    }

    try {
      setLoading(true);
      const baseUrl = CONFIG.firebaseFunctions.baseUrl;
      const endpoint = '/support/email';

      const url = `${baseUrl}${endpoint}`;

      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(helpFormData), // body data type must match "Content-Type" header
      });

      const responsePayload: HelpFormData = await response.json();

      // If the data sent to BE matches what BE responds back, it means the message was successfully sent.
      if (
        responsePayload.email === helpFormData.email &&
        responsePayload.message === helpFormData.message
      ) {
        toast.success('Mensagem enviada.');
        setMessageSent(true);
        setLoading(false);
      } else {
        throw new Error("response and request payloads don't match!");
      }
    } catch (e) {
      toast.error(<>O envio falhou. Por favor, tenta novamente daqui pouco.</>);
      setLoading(false);
    }
  }

  const buttonText = submitButtonText ? submitButtonText : 'Enviar mensagem';

  return (
    <CustomForm onSubmit={handleSubmit}>
      {introElement ? (
        introElement
      ) : (
        <h3>
          Tens dúvidas quanto à utilização da aplicação, sugestões de melhoria,
          ou outro assunto? 🙂
          <br />
          <br />
          <small>Independentemente, estamos ansiosos por saber.</small>
          <br />
          <br />
          Não hesites, manda-nos uma mensagem.
          <br />
          <small>Respondemos dentro de 24h.</small>
        </h3>
      )}

      <br />

      <label htmlFor="email">E-mail *</label>
      <GInput
        placeholder={emailInputPlaceholder || 'O teu e-mail'}
        readOnly={messageSent}
        disabled={!!currentUser?.email || messageSent}
        type="email"
        id="email"
        name="email"
        required
        value={currentUser?.email || helpFormData.email || ''}
        onChange={(e) =>
          setHelpFormData({
            ...helpFormData,
            email: e?.target?.value,
          })
        }
      />
      <br />
      <br />
      <label htmlFor="message">Mensagem *</label>
      <GTextarea
        disabled={messageSent}
        readOnly={messageSent}
        maxLength={1000}
        required
        id="message"
        name="message"
        placeholder={
          messageInputPlaceholder
            ? messageInputPlaceholder
            : 'Escreve a tua mensagem aqui...'
        }
        onChange={(e) =>
          setHelpFormData({
            ...helpFormData,
            message: e?.target?.value,
          })
        }
      />

      <br />
      <br />

      {messageSent ? (
        <GMessage status="success" title="A tua mensagem foi enviada.">
          <p>
            Vamos-te responder de volta para o <b>{helpFormData.email}</b> o
            mais rapidamente possível, tipicamente num período de 24h.
          </p>

          <br />

          <p>Gratos pela tua mensagem! 🙂</p>
        </GMessage>
      ) : (
        <GButton
          type="submit"
          disabled={loading}
          $fontSize={submitButtonFontSize}
        >
          {loading ? 'A enviar...' : buttonText}
        </GButton>
      )}

      <br />
      <br />

      <small>
        <b>Os campos com * são obrigatórios.</b>
      </small>
    </CustomForm>
  );
}

const CustomForm = styled.form<{ $generic?: boolean }>`
  width: 100%;
  max-width: 580px;

  label {
    font-weight: bold;
  }

  #message {
    height: 8em;
    word-spacing: 0.2em;
  }

  input {
    word-spacing: 0.2em;
  }
`;
