import { FaCircleCheck } from 'react-icons/fa6';
import styled from 'styled-components';
import GButton from '../../components/GButton/GButton';
import { Link } from 'react-router-dom';

export default function Success() {
  return (
    <Wrapper>
      <div className="success-box">
        <span>
          <FaCircleCheck />
        </span>

        <h1 className="sucesso-text">Sucesso!</h1>
        <p>Agradecemos a tua confiança!</p>

        <br />
        <br />

        <p>
          <Link to="/chat">
            <GButton>Começar</GButton>
          </Link>
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 80px);

  span {
    font-size: 8em;
    color: #1a73e8;
    animation: rotateAndScale 1s;
  }

  .sucesso-text {
    animation: opax 0.5s;
    color: #1a73e8;
  }

  .success-box {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @keyframes opax {
    from {
      opacity: 0;
      transform: translateY(-15px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes rotateAndScale {
    from {
      rotate: 0deg;
      scale: 0.5;
    }
    to {
      rotate: 360deg;
      scale: 1;
    }
  }
`;
